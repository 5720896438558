<template>
  <div class="box">
    <div class="head">
      <img src="../../assets/hire/detail.png" alt="" />
      <h1 class="w">岗位详情</h1>
    </div>
    <div class="breads">
      <div class="bread w">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/hire' }"
            >人才招聘</el-breadcrumb-item
          >
          <el-breadcrumb-item class="active">岗位详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="content w">
      <div class="big-card">
        <h1>
          <span class="job-title">{{ detailInfo.positionName }}</span>
          <span class="salary">{{ detailInfo.basicSalary }}</span>
        </h1>
        <p class="one">{{ detailInfo.workExperience }}</p>
        <div>
          <h3>岗位要求</h3>
          <p v-html="detailInfo.positionRequirement"></p>
        </div>
        <div>
          <h3>岗位职责</h3>
          <p v-html="detailInfo.positionDuty"></p>
        </div>
      </div>

      <div class="titles">
        <img src="../../assets/hire/teaml.png" alt="" />
        <h3>其他岗位</h3>
      </div>

      <div class="cards">
        <div
          class="small-card"
          v-for="item in resetJob"
          :key="item.id"
          @click="handleClick(item.id)"
        >
          <h2>
            <span>{{ item.positionName }}</span>
            <span>{{ item.basicSalary }}</span>
          </h2>
          <p>{{ item.workExperience }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hireInfo, hireDetail } from '../../api/hire.js'
export default {
  name: 'Box',
  components: {},
  created() {
    let id = this.$route.params.id
    if (id) {
      this.initData(id)
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.initData(to.params.id)
    next()
  },
  data() {
    return {
      detailInfo: {},
      resetJob: []
    }
  },
  methods: {
    initData(id) {
      hireDetail({ id }).then((res) => {
        if (res.status === 200) {
          this.detailInfo = res.data ? res.data : {}
        }
      })

      hireInfo().then((res) => {
        console.log('qqqqq', res.data.recruitmentPostList)
        if (res.status === 200) {
          // 去掉id已经存在的数据
          this.resetJob = res.data.recruitmentPostList.filter((item) => {
            return item.id !== id
          })
        }
      })
    },
    handleClick(id) {
      this.$router.push(`/detail/${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.breads {
  width: 100%;
  height: 60px;
  background-color: #eee;
  .bread {
    height: 100%;
    display: flex;
    align-items: center;
    .active /deep/.el-breadcrumb__inner {
      color: #2473e9;
      font-size: 16px;
      font-weight: 400;
    }
  }
  /deep/.el-breadcrumb__item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: #333333;
  }
}
/deep/.el-breadcrumb__inner.is-link {
  font-weight: 400;
  color: #606266;
}
.box {
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
  background-color: #f9f9f9;
}
.head {
  widows: 100vw;
  height: 320px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  h1 {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 2;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 48px;
    color: #fff;
  }
}
.content {
  padding: 80px 0;
  .big-card {
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
    -webkit-box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    text-align: left;
    h1 {
      display: flex;
      align-items: center;
      .job-title {
        font-family: 'OPPOSans-B';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 48px;
        color: #333333;
        margin-right: 30px;
      }
      .salary {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 48px;
        color: #2473e9;
      }
    }
    p.one {
      font-family: 'OPPOSans-H';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: #333333;
      margin-bottom: 32px;
    }
    > div {
      margin-bottom: 50px;
      h3 {
        font-family: 'OPPOSans-B';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: #333333;
        margin-bottom: 30px;
      }
      p {
        font-family: 'OPPOSans-M';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: #666666;
      }
    }
  }
  .titles {
    display: flex;
    align-items: center;
    margin: 56px 0 24px;
    img {
      width: 36px;
      height: 36px;
      margin-right: 20px;
    }
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      color: #333333;
    }
  }
  .cards {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .small-card {
      cursor: pointer;
      padding: 24px;
      box-sizing: border-box;
      width: 364px;
      margin-right: 35px;
      margin: 20px 35px 30px 0px;
      background-color: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      border: 1px solid rgba(255, 255, 255, 0.18);
      box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
      -webkit-box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
      border-radius: 16px;
      -webkit-border-radius: 16px;
      h2 {
        display: flex;
        align-items: center;
        span {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 48px;
          color: #2473e9;
          font-family: 'OPPOSans-H';
          &:nth-child(1) {
            margin-right: 20px;
            color: #333333;
          }
          &:nth-child(2) {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
      p {
        font-family: 'OPPOSans-H';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        color: #666;
      }
    }
  }
}
</style>
